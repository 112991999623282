import Accordion from '../Accordion'

const DDLangMenu = () => {
	return (
		<Accordion>
				<div className="acc-container menu-dd-lang style-1 acc-hover">
					<div className="acc-parent row-flex g-8"><i className="icon icon-flag-en s-20"></i><span className="label">English</span></div>
					<div className="acc-content">
						<div className="inner-height">
							<div className="arrow"></div>
							<div className="inner">
								<ul>
									<li>
										<span className="row row-flex g-8">
											<i className="icon icon-flag-cn s-20"></i><span className="label">Chinese</span>
										</span>
									</li>
									<li>
										<span className="row row-flex g-8">
											<i className="icon icon-flag-vn s-20"></i><span className="label">Vietnamese</span>
										</span>
									</li>
									<li>
										<span className="row row-flex g-8">
											<i className="icon icon-flag-id s-20"></i><span className="label">Bahasa</span>
										</span>
									</li>
									<li>
										<span className="row row-flex g-8">
											<i className="icon icon-flag-th s-20"></i><span className="label">Thai</span>
										</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
		</Accordion>
	)
}

export default DDLangMenu
