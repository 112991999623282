import { Link, NavLink } from 'react-router-dom'

const Card = (props) => {
      const truncateString = (str, num) => {
            if (num > str.length){
                  return str
            } else{
                  str = str.substring(0,num)
                  return str+"..."
            }
      }

      const style1 = () => {
            return (
                  <div className="card top-news-card style-1">
                        <Link to="/news/single-post">
                              <div className="img-holder">
                                    <img src={props.src} alt={props.alt} />
                                    <div className="info">
                                          <div className="meta row row-1 row-flex flex-s g-8">
                                                <div className="col col-1"><strong>{props.cat}</strong></div>
                                                <div className="col col-2">|</div>
                                                <div className="col col-3">{props.date}</div>
                                          </div>
                                          <h4 className="title row row-2">{props.title}</h4>
                                    </div>
                              </div>
                        </Link>
                  </div>
            )
      }
      
      const style2 = () => {
            return (
                  <div className="card top-news-card style-2">
                        <div className="img-holder row m-b-16">
                              <img src={props.src} alt={props.alt} />
                        </div>
                        <div className="info">
                              <div className="meta row row-1 row-flex flex-s g-8 m-b-16">
                                    <div className="col col-1"><strong>{props.cat}</strong></div>
                                    <div className="col col-2">|</div>
                                    <div className="col col-3">{props.date}</div>
                              </div>
                              <h4 className="title row row-2">{props.title}</h4>
                              <p className="excerpt row row-3">{truncateString(props.excerpt, 75)}&nbsp;
                              <NavLink to="/news/single-post" className="link"><strong>Read more</strong></NavLink></p>
                        </div>
                  </div>
            )
      }
      
      const style3 = () => {
            return (
                  <div className="card top-news-card style-3">
                        <div className="row row-flex flex-s g-16">
                              <div className="col col-1">
                                    <div className="img-holder">
                                          <img src={props.src} alt={props.alt} />
                                    </div>
                              </div>
                              <div className="col col-2">
                                    <div className="info">
                                          <div className="meta row row-1 row-flex flex-s g-8 m-b-16">
                                                <div className="col col-1"><strong>{props.cat}</strong></div>
                                                <div className="col col-2">|</div>
                                                <div className="col col-3">{props.date}</div>
                                          </div>
                                          <h4 className="title row row-2">{props.title}</h4>
                                          <p className="excerpt row row-3">{truncateString(props.excerpt, 155)}&nbsp;
                                          <NavLink to="/news/single-post" className="link"><strong>Read more</strong></NavLink></p>
                                    </div>
                              </div>
                        </div>
                  </div>
            )
      }

      const renderCard = () => {
            if(props['card-class'].includes('style-1')) {
                  return (style1())
            } else if(props['card-class'].includes('style-2')) {
                  return (style2())
            } else if(props['card-class'].includes('style-3')) {
                  return (style3())
            }
      }
      
      return (
            renderCard()
      )
}

export default Card
