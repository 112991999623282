import { useState, useEffect, useCallback } from 'react'

const DropDown = (elem) => {
      const [ddActive, setDDdActive] = useState(false)

      const handleDDClick = useCallback((e) => {
            if (elem.current && elem.current.contains(e.target)) {
                  if(e.target.closest('.selected')) {
                        setDDdActive(!ddActive)
                  } else {
                        setDDdActive(true)
                  }
            } else {
                  setDDdActive(false)
            }
      }, [ddActive, elem])

      useEffect(() => {
            if(ddActive) {
                  if(elem.current.offsetWidth < elem.current.querySelector(".options").offsetWidth) {
                        elem.current.style.width = elem.current.querySelector(".options").offsetWidth+"px"
                  } else {
                        elem.current.querySelector(".options").style.width = elem.current.offsetWidth+"px"
                  }
            } else {
                  elem.current.style.width = ""
            }
            document.addEventListener('mousedown', handleDDClick);
            return () => document.removeEventListener('mousedown', handleDDClick);
      }, [ddActive, handleDDClick, elem]);

      return { handleDDClick, ddActive }
}

export default DropDown
