const Card = (props) => {
      const btnClass = [
            'btn',
            ...props['btn-w'] ? ['w-'+props['btn-w']] : ['w-200'],
            ...props['btn-h'] ? ['h-'+props['btn-h']] : ['h-40'],
            ...props.deactive ? ['deactive'] : [],
            ...props.className ? [props.className] : [],
      ]

      return (
            <div className="card live-casino-card">
                  <img src={props['bg-src']} alt="Banner" />
                  <button className={btnClass.join(' ')} disabled={((props.deactive || props.disabled) && 1)}><span>{props['btn-txt']}</span></button>
            </div>
      )
}

export default Card
