import React from 'react'

const Odds = () => {
      return (
            <div className="odds">
                  <div className="row row-flex flex-b g-4">
                        <div className="col col-1 row-flex flex-b">
                              <div className="col col-1">1</div>
                              <div className="col col-2 row-flex flex-s g-4">
                                    <i className="icon icon-triangle red s-8"></i>
                                    <span>00.00</span>
                              </div>
                        </div>
                        <div className="col col-2 row-flex flex-b">
                              <div className="col col-1">X</div>
                              <div className="col col-2">00.00</div>
                        </div>
                        <div className="col col-3 row-flex flex-b">
                              <div className="col col-1">2</div>
                              <div className="col col-2 row-flex flex-s g-4">
                                    <i className="icon icon-triangle green s-8"></i>
                                    <span>00.00</span>
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default Odds
