import QuickTransferLogo from '../../assets/images/logos/quick-transfer-blue-pale.svg'
import QuickPayLogo from '../../assets/images/logos/quick-pay-blue-pale.svg'
import P2pcnLogo from '../../assets/images/logos/p2pcn-blue-pale.svg'
import OpusGamingLogo from '../../assets/images/logos/opus-gaming-blue-pale.svg'
import SabaSportsLogo from '../../assets/images/logos/saba-sports-blue-pale.svg'
import PragmaticPlayLogo from '../../assets/images/logos/pragmatic-play-blue-pale.svg'
import HabaneroLogo from '../../assets/images/logos/habanero-blue-pale.svg'
import PagcorLogo from '../../assets/images/logos/pagcor-blue-pale.svg'
import GALogo from '../../assets/images/logos/ga-blue-pale.svg'
import ItechLogo from '../../assets/images/logos/itech-blue-pale.svg'
import "../../assets/css/footer/index.scss";

const index = () => {
      return (
            <footer id="footer">
                  <div className="row row-1">
                        <div className="inner-box">
                              <div className="row row-1 row-flex flex-b">
                                    <div className="col col-1">
                                          <ul className="row-flex flex-s g-8">
                                                <li>Available Payments:</li>
                                                <li><img src={QuickTransferLogo} alt="Quick Transfer" /></li>
                                                <li><img src={QuickPayLogo} alt="Quick Pay" /></li>
                                                <li><img src={P2pcnLogo} alt="P2Pcn" /></li>
                                          </ul>
                                    </div>
                                    <div className="col col-2">
                                          <nav className="footer-menu">
                                                <ul className="row-flex flex-s">
                                                      <li><span>About Us</span></li>
                                                      <li><span>Refer Friend</span></li>
                                                      <li><span>VIP</span></li>
                                                      <li><span>Affiliates</span></li>
                                                      <li><span>Responsible Gaming</span></li>
                                                      <li><span>Help Center</span></li>
                                                      <li><span>Contact Us</span></li>
                                                </ul>
                                          </nav>
                                    </div>
                              </div>
                              <div className="row row-2">
                                    <ul className="row-flex flex-s g-24">
                                          <li><img src={OpusGamingLogo} alt="Opus Gaming" /></li>
                                          <li><img src={SabaSportsLogo} alt="Saba Sports" /></li>
                                          <li><img src={PragmaticPlayLogo} alt="Pragmatic Play" /></li>
                                          <li><img src={HabaneroLogo} alt="Habanero" /></li>
                                    </ul>
                              </div>
                        </div>
                  </div>
                  <div className="row row-2">
                        <div className="inner-box">
                              <div className="row row-1 row-flex flex-b">
                                    <div className="col col-1">
                                          <ul className="row-flex flex-s g-16">
                                                <li><img src={PagcorLogo} alt="Pagcor" /></li>
                                                <li><img src={GALogo} alt="GA" /></li>
                                                <li><img src={ItechLogo} alt="Itech" /></li>
                                          </ul>
                                    </div>
                                    <div className="col col-2">
                                          Copyright 2021  |  MGOAL88  |  All Rights Reserved
                                    </div>
                              </div>
                        </div>
                  </div>
            </footer>
      )
}

export default index
