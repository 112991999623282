import loginReducer from './login'
import loginUserReducer from './loginuser'
import registerReducer from './register'
import { combineReducers } from 'redux'

const allReducers = combineReducers({
      showLogin: loginReducer,
      loginUser: loginUserReducer,
      showRegister: registerReducer,
})

export default allReducers