import Card from './Card'
import '../../assets/css/news.scss'
import PromoBanner from '../PromoBanner'
import BGMSports from '../../assets/images/bg/promo-cards/sports/m-sports.jpg'
import BGSabaSports from '../../assets/images/bg/promo-cards/sports/saba-sports.jpg'
import LogoMSports from '../../assets/images/logos/m-sports-white.svg'
import LogoSabaSports from '../../assets/images/logos/saba-sports-white.svg'
import Soccer1 from '../../assets/images/misc/top-news/soccer-1.jpg'
import Tennis1 from '../../assets/images/misc/top-news/tennis-1.jpg'
import basketball from '../../assets/images/misc/top-news/basketball-1.png'
import esports from '../../assets/images/misc/top-news/e-sports-1.jpg'
import mma from '../../assets/images/misc/top-news/mma-1.jpg'
import boxing from '../../assets/images/misc/top-news/boxing-1.jpg'
import { useDispatch, useSelector } from 'react-redux'

const HomeModule = (props) => {
      const loginUser = useSelector(state => state.loginUser)
	const dispatch = useDispatch()

      return (
            <div className="top-news">
                  <div className="heading-row row m-b-40">
                        {props.heading}
                  </div>
                  <div className="row row-1 row row-flex flex-b g-1 m-b-32">
                        <div className="col col-1">
                              <Card src={Soccer1} card-class="style-1" alt="soccer-1"
                              cat="Soccer" date="June 19, 2021"
                              title="News headline better in lowercase and  check longer" />
                        </div>
                        <div className="col col-2">
                              <div className="row row-flex g-1 m-b-1">
                                    <div className="col col-1">
                                          <Card src={Tennis1} card-class="style-1" alt="tennis-1"
                                          cat="Tennis" date="June 19, 2021"
                                          title="News headline better in lowercase and  check longer" />
                                    </div>
                                    <div className="col col-2">
                                          <Card src={Tennis1} card-class="style-1" alt="tennis-1"
                                          cat="Tennis" date="June 19, 2021"
                                          title="News headline better in lowercase and  check longer" />
                                    </div>
                              </div>
                              <div className="row row-flex g-1">
                                    <div className="col col-1">
                                          <Card src={Tennis1} card-class="style-1" alt="tennis-1"
                                          cat="Tennis" date="June 19, 2021"
                                          title="News headline better in lowercase and  check longer" />
                                    </div>
                                    <div className="col col-2">
                                          <Card src={Tennis1} card-class="style-1" alt="tennis-1"
                                          cat="Tennis" date="June 19, 2021"
                                          title="News headline better in lowercase and  check longer" />
                                    </div>
                              </div>
                        </div>
                  </div>
                  <div className="row row-2 row row-flex flex-b g-16 m-b-32">
                        <div className="col col-1">
                              <PromoBanner bg-src={BGMSports} logo-src={LogoMSports} slogan="MAKE SPORTS MORE EXCITING" btn-txt="PLAY NOW" />
                        </div>
                        <div className="col col-2">
                              <PromoBanner bg-src={BGSabaSports} logo-src={LogoSabaSports} slogan="EXPERIENCE SPORTS IN A NEW WAY" btn-txt="MAINTENANCE" deactive />
                        </div>
                  </div>
                  <div className="row row-3 row row-flex flex-b g-16 m-b-32">
                        <div className="col col-1">
                              <Card src={basketball} card-class="style-2" alt="Basketball"
                              cat="Basketball" date="June 19, 2021"
                              title="News headline better in lowercase and  check longer"
                              excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                        </div>
                        <div className="col col-2">
                              <Card src={esports} card-class="style-2" alt="e sports"
                              cat="E-Sports" date="June 19, 2021"
                              title="News headline better in lowercase and  check longer"
                              excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                        </div>
                        <div className="col col-3">
                              <Card src={mma} card-class="style-2" alt="mma"
                              cat="Martial Arts" date="June 19, 2021"
                              title="News headline better in lowercase and  check longer"
                              excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                        </div>
                        <div className="col col-4">
                              <Card src={boxing} card-class="style-2" alt="Boxing"
                              cat="Boxing" date="June 19, 2021"
                              title="News headline better in lowercase and  check longer"
                              excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                        </div>
                  </div>
            </div>
      )
}

export default HomeModule
