let oldMousePos, oldElemPos
let scrollPos = ''
let pagePos = ''
let offsetPos = ''
	
const swipeList = e => {
      const elem = e.currentTarget
      elem.querySelector('.list-swipe').classList.add('active')
      elem[scrollPos] = oldElemPos + oldMousePos - (e[pagePos] - elem[offsetPos])
}

export const handleSwipeMouseDown = e => {
      if(e.button !== 1) {
            const elem = e.currentTarget
            const swipeY = (elem.querySelector('.list-swipe').classList.contains("swipe-y"))
            scrollPos = (swipeY) ? 'scrollTop' : 'scrollLeft'
            pagePos = (swipeY) ? 'pageY' : 'pageX'
            offsetPos = (swipeY) ? 'offsetTop' : 'offsetLeft'
            oldMousePos = e[pagePos] - elem[offsetPos]
            oldElemPos = elem[scrollPos]
            elem.addEventListener('mousemove', swipeList)
      }
}

export const handleSwipeMouseUp = e => {
      const elem = e.currentTarget
      elem.querySelector('.list-swipe').classList.remove('active')
      elem.removeEventListener('mousemove', swipeList)
}