import { Link, NavLink } from 'react-router-dom'
import "../../assets/css/header/index.scss";
import logo from '../../assets/images/logos/m-goal-white.svg';
import DDLangMenu from './DDLangMenu'
import { useDispatch, useSelector } from 'react-redux'
import { showLogin, showRegister } from '../../actions'

const Index = () => {
	const loginUser = useSelector(state => state.loginUser)
	const dispatch = useDispatch()

	return (
		<header id="header">
			<div className="row row-1">
				<div className="inner-box row row-flex flex-b">
					<div className="col col-1"><Link to="/"><img src={logo} alt="M Goal Logo" /></Link></div>
					<div className="col col-2">
						<ul className="row-flex flex-s g-16">
							{!loginUser ? <>
								<li><button className="btn transparent light h-40" onClick={() => dispatch(showLogin())}><span>LOGIN</span></button></li>
								<li><button className="btn h-40" onClick={() => dispatch(showRegister())}><span>JOIN NOW</span></button></li> </> : <>
								<li>MYR 10,000,000.43</li>
								<li><button className="btn h-40"><span>DEPOSIT</span></button></li></>
							}
						</ul>
					</div>
				</div>
			</div>
			<div className="row row-2">
				<div className="inner-box row-flex flex-b">
					<div className="col col-1">
						<nav className="main-menu">
							<ul className="row-flex flex-s g-16">
								<li><NavLink to="/" exact>HOME</NavLink></li>
								<li><NavLink to="/news">NEWS</NavLink></li>
								<li>SPORTSBOOK</li>
								<li>LIVE CASINO</li>
								<li>CASINO</li>
								<li>PROMOTIONS</li>
								<li>VIP</li>
							</ul>
						</nav>
					</div>
					<div className="col col-1 row-flex g-16">
						<div className="col col-1"><button className="btn-lc row-flex g-4"><i className="icon icon-chat s-20"></i><span>Live Help</span></button></div>
						<div className="col col-2">
							<DDLangMenu />
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default Index
