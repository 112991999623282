import { useRef } from 'react'
import dropDown from '../../libraries/dropDown'
import { handleSwipeMouseDown, handleSwipeMouseUp } from '../../libraries/swipeList'
import Card from './Card'
import '../../assets/css/match-sched.scss';
import mSportsLogoBlack from '../../assets/images/logos/m-sports-black.svg'

const HomeModule = (props) => {
	const ddRef = useRef()
	const { handleDDClick, ddActive } = dropDown(ddRef)

      return (
            <div className="match-sched">
                  <div className="inner-box">
				<div className="heading-row row row-flex flex-b m-b-40">
					<div className="col col-1">
						<div className="row row-flex g-16">
							<div className="col col-1">{props.heading}</div>
							<div className="col col-2">
								<div className={"dropdown"+((ddActive) ? " active": "")} ref={ddRef} onClick={() => handleDDClick}>
									<div className="selected item row-flex flex-b"><span>LIVE NOW</span><i className="icon icon-caret-down-dark s-16"></i></div>
									<div className="options">
										<div className="item"><span>LIVE NOW</span></div>
										<div className="item"><span>TODAY</span></div>
										<div className="item"><span>UPCOMING</span></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col col-2 row-flex flex-b g-16">
						<div className="col col-1">Powered by</div>
						<div className="col col-2"><img src={mSportsLogoBlack} alt="M Sports" /></div>
						<div className="col col-3"><button className="btn h-32"><span>BET NOW</span></button></div>
					</div>
				</div>
				<div className="overflow-swipe" onMouseDown={handleSwipeMouseDown}
				onMouseUp={handleSwipeMouseUp} onMouseLeave={handleSwipeMouseUp}>
					<ul className="list-swipe swipe-x row-flex flex-s g-8">
						<li><Card /></li>
						<li><Card /></li>
						<li><Card /></li>
						<li><Card /></li>
						<li><Card /></li>
						<li><Card /></li>
						<li><Card /></li>
						<li><Card /></li>
						<li><Card /></li>
					</ul>
				</div>
                  </div>
            </div>
      )
}

export default HomeModule
