import { useDispatch } from 'react-redux'
import { useState, useEffect } from "react"

const UseForm = (formInputs) => {
      const [formState, setFormState] = useState(formInputs)
      const [eventState, setEventState] = useState(null)
      const [allowSubmit, setAllowSubmit] = useState(false)
      const dispatch = useDispatch()

      const handleChange = (e) => {
            let {name, value} = e.target
            value = value.slice(0, formState[e.target.name].max)
            let totalError = 0

            setFormState(prevState => ({
                  ...prevState,
                  [name]: {
                        ...prevState[name],
                        validated: true,
                        value: value,
                  }
            }))

            setEventState(e)

            if(formState[name].hasOwnProperty('min') || formState[name].hasOwnProperty('max')) {
                  const min = formState[name].min || 1
                  const max = formState[name].max || 9999999
                  
                  if(!(value.length >= min && value.length <= max)) {
                        totalError++
                  }
            }

            setFormState(prevState => ({
                  ...prevState,
                  [name]: {
                        ...prevState[name],
                        valid: (!totalError) ? true : false
                  }
            }))
      }

      const handleSubmit = (e, action) => {
            e.preventDefault()
            dispatch(action)
            dispatch({type: 'hide_login'})
      }

      const filterObj = (obj, predicate) => {
            var result = {}, key;
        
            for (key in obj) {
                if (obj.hasOwnProperty(key) && predicate(key, obj[key])) {
                    result[key] = obj[key];
                }
            }
        
            return result;
      }
    
      Object.size = function(obj) {
            var size = 0,
                key;
            for (key in obj) {
                if (obj.hasOwnProperty(key)) size++;
            }
            return size;
      };

      useEffect(() => {
            var filter = filterObj( formState, function( key, property ) {
                  return property.valid === false
            })
      
            setAllowSubmit(!Object.size(filter) ? true: false)
      }, [formState, eventState])

      return { handleChange, handleSubmit, formState, allowSubmit }
}

export default UseForm
