import { handleSwipeMouseDown, handleSwipeMouseUp } from '../../libraries/swipeList'
import '../../assets/css/recently-played.scss'
import '../../assets/css/casino.scss'
import Card from '../casino/Card'
import CasinoThumb2 from '../../assets/images/casino/thumb/dragon-shard.jpg'
import CasinoThumb3 from '../../assets/images/casino/thumb/fire-rooster.jpg'
import CasinoThumb4 from '../../assets/images/casino/thumb/eye-amulet.jpg'

const Index = (props) => {
      return (
            <div className="recently-played">
                  <div className="heading-row row m-b-40">
                        {props.heading}
                  </div>
                  <div className="row row-1 m-b-32">
                        <div className="overflow-swipe" onMouseDown={handleSwipeMouseDown}
				onMouseUp={handleSwipeMouseUp} onMouseLeave={handleSwipeMouseUp}>
                              <ul className="list-swipe swipe-x row-flex flex-s g-16">
                                    <li><div className="inner"><Card thumb-src={CasinoThumb2} title="Dragon Shard" provider="microgaming" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb3} title="Fire Rooster" provider="habanero" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb4} title="Eye Amulet" provider="isoftbet" /></div></li>
                              </ul>
                        </div>
                  </div>
            </div>
      )
}

export default Index
