import Odds from '../Odds'

const Card = () => {
	return (
		<div className="card match-sched-card">
			<div className="row row-1 row-flex flex-b">
				<div className="col col-1">League Name</div>
				<div className="col col-2"><span>00:00</span><span className="tag live">LIVE</span></div>
			</div>
			<div className="row row-2">
				<div className="row row-1 row-flex flex-s g-8"><i className="icon icon-arsenal s-24"></i><span>TEAM 1</span></div>
				<div className="row row-2 row-flex flex-s g-8"><i className="icon icon-manchester-city s-24"></i><span>TEAM 2</span></div>
			</div>
			<div className="row row-3">
				<Odds />
			</div>
		</div>
	)
}

export default Card
