import '../../assets/css/login.scss'
import MgoalLogo from '../../assets/images/logos/m-goal-white.svg'
import useForm from '../../libraries/useForm'

const Login = () => {
      const formInputs = {
            username: {
                  type: 'text',
                  value: '',
                  validated: false,
                  valid: false,
                  required: true,
                  valMsg: ""
            },
            password: {
                  type: 'password',
                  value: '',
                  validated: false,
                  valid: false,
                  required: true,
                  min: 8,
                  max: 10,
                  valMsg: ""
            }
      }

      const { formState, handleChange, handleSubmit, allowSubmit } = useForm(formInputs)
      
      return (
            <div className="popup popup-login dark">
                  <div className="popup-heading row-flex flex-e">
                        <button><i className="icon icon-close-white s-16"></i></button>
                  </div>
                  <div className="popup-content">
                        <div className="inner">
                              <div className="logo talign-center"><img src={MgoalLogo} alt="M Goal" /></div>
                              <form onSubmit={(e) => handleSubmit(e, {type: 'login_user'})}>
                                    <div className="row row-1 m-b-32">
                                          <div className="label row m-b-16"><label htmlFor="username"><strong>Username</strong></label></div>
                                          <div className="input-field">
                                                <input type="text" id="username" name="username"
                                                placeholder="Input your registered username"
                                                value={formState.username.value}
                                                onChange={handleChange} />

                                                {(formState.username.validated && formState.username.valid) && <i className="icon icon-valid-green s-24 icon-validation"></i>}
                                                {(formState.username.validated && !formState.username.valid) && <i className="icon icon-invalid-red s-24 icon-validation"></i>}
                                          </div>
                                    </div>
                                    <div className="row row-2 m-b-32">
                                          <div className="label row m-b-16"><label htmlFor="password"><strong>Password</strong></label></div>
                                          <div className="input-field">
                                                <input type="password" id="password" name="password" autoComplete="on"
                                                placeholder="8-10 characters long"
                                                value={formState.password.value}
                                                onChange={handleChange} />

                                                {(formState.password.validated && formState.password.valid) && <i className="icon icon-valid-green s-24 icon-validation"></i>}
                                                {(formState.password.validated && !formState.password.valid) && <i className="icon icon-invalid-red s-24 icon-validation"></i>}
                                          </div>
                                    </div>
                                    <div className="row row-3 row-flex flex-s g-8 m-b-32">
                                          <div className="col col-1">
                                                <div className="input-checkbox">
                                                      <input type="checkbox" id="remember" name="remember" />
                                                      <i className="icon icon-check-white"></i>
                                                </div>
                                          </div>
                                          <div className="col col-2">
                                                <label htmlFor="remember">Keep me signed in</label>
                                          </div>
                                    </div>
                                    <div className="row row-4 m-b-16">
                                          <button className={"btn w-100p h-40"+(!allowSubmit ? ' deactive' : '')}
                                          disabled={!allowSubmit ? true : false}><span>LOGIN</span></button>
                                    </div>
                                    <div className="row row-5 m-b-38 talign-center">
                                          <p>Forgot your login details?</p>
                                    </div>
                                    <div className="row row-6 talign-center">
                                          <p>Not a member yet? Create a new account!</p>
                                    </div>
                              </form>
                        </div>
                  </div>
            </div>
      )
}

export default Login
