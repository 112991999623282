import { handleSwipeMouseDown, handleSwipeMouseUp } from '../../libraries/swipeList'
import '../../assets/css/casino.scss'
import Card from './Card'
import CasinoThumb2 from '../../assets/images/casino/thumb/dragon-shard.jpg'
import CasinoThumb1 from '../../assets/images/casino/thumb/dragon-dozer.jpg'
import CasinoThumb3 from '../../assets/images/casino/thumb/fire-rooster.jpg'
import CasinoThumb4 from '../../assets/images/casino/thumb/eye-amulet.jpg'
import CasinoThumb5 from '../../assets/images/casino/thumb/aladdin-sorcerer.jpg'
import PragmaticPlayLogo from '../../assets/images/logos/pragmatic-play.svg';
import HabaneroLogo from '../../assets/images/logos/habanero.svg';

const HomeModule = (props) => {
      return (
            <div className="casino-games">
                  <div className="heading-row row m-b-40">
                        {props.heading}
                  </div>
                  <div className="row row-1 m-b-32">
                        <div className="overflow-swipe" onMouseDown={handleSwipeMouseDown}
				onMouseUp={handleSwipeMouseUp} onMouseLeave={handleSwipeMouseUp}>
                              <ul className="list-swipe swipe-x row-flex flex-s g-16">
                                    <li><div className="inner"><Card thumb-src={CasinoThumb2} title="Dragon Shard" provider="microgaming" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb3} title="Fire Rooster" provider="habanero" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb4} title="Eye Amulet" provider="isoftbet" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb1} title="Dragon Dozer" provider="skywind" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb5} title="Aladdin Sorcerer" provider="cq9" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb2} title="Dragon Shard" provider="microgaming" /></div></li>
                                    <li><div className="inner"><Card thumb-src={CasinoThumb3} title="Fire Rooster" provider="habanero" /></div></li>
                              </ul>
                        </div>
                  </div>
                  <div className="row row-2 row-flex flex-b g-16">
                        <div className="col col-1">
                              <div className="game-provider">
                                    <img src={PragmaticPlayLogo} alt="Pragmatic Play" />
                              </div>
                        </div>
                        <div className="col col-2">
                              <div className="game-provider">
                                    <img src={HabaneroLogo} alt="Habanero" />
                              </div>
                        </div>
                  </div>
            </div>
      )
}

export default HomeModule
