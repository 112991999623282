export const showLogin = () => {
      return {
            type: 'show_login'
      }
}

export const hideLogin = () => {
      return {
            type: 'hide_login'
      }
}

export const loginUser = () => {
      return {
            type: 'login_user'
      }
}

export const showRegister = () => {
      return {
            type: 'show_register'
      }
}