const PromoBanner = (props) => {
      const bannerClass = [
            'card promo-card',
            ...props['banner-class'] ? [props['banner-class']] : [],
      ]

      const isFullWidth = bannerClass.join(' ').includes('full-width')

      const btnClass = [
            'btn',
            ...props['btn-w'] ? ['w-'+props['btn-w']] : [],
            ...props['btn-h'] ? ['h-'+props['btn-h']] : ['h-40'],
            ...props.deactive ? ['deactive'] : [],
            ...props.className ? [props.className] : [],
      ]
      

      return (
            <div className={bannerClass.join(' ')} style={{backgroundImage: 'url('+props['bg-src']+')'}}>
                  <div className="row row-flex flex-b g-40">
                        <div className="col col-1">
                              {(isFullWidth) ?
                                    (<div className={"row row-flex flex-b g-32"}>
                                          <div className="col col-1">
                                                <img src={props['logo-src']} alt="m sports" className="logo" />
                                          </div>
                                          <div className="col col-2">
                                                <p className="slogan">{props.slogan}</p>
                                          </div>
                                    </div>)
                              : (<>
                                    <img src={props['logo-src']} alt="m sports" className="logo" />
                                    <p className="slogan">{props.slogan}</p>
                              </>)}
                              
                        </div>
                        <div className="col col-2">
                              <button className={btnClass.join(' ')} disabled={((props.deactive || props.disabled) && 1)}><span>{props['btn-txt']}</span></button>
                        </div>
                  </div>
            </div>
      )
}

export default PromoBanner
