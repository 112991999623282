import React, {useRef, useEffect} from 'react';

export function AccParent() {
    return (<></>)
}

export function AccContent() {
    return (<></>)
}

const Accordion = (props) => {
    const arrayAcc = []
    const accContainer = useRef()
    const accHTMLArray = []
    
    arrayAcc.push(props.children)
    arrayAcc.map((item) => (
        accHTMLArray.push(item)
    ))

    useEffect(() => {
        let accExpandedTimer = null

        const accordionOver = (elem) => {
            elem.currentTarget.classList.add('active')
            const a = elem.currentTarget.parentNode.querySelector(".inner-height")
            const b = elem.currentTarget.parentNode.querySelector(".acc-content")
            const accInnerHeight = a.clientHeight;
            b.style.height = accInnerHeight + 'px'
            accExpandedTimer = setTimeout(function(){
                b.style.height = 'unset'
            }, 250)
        }
    
        const accordionOut = (elem) => {
            elem.currentTarget.classList.remove('active')
            const b = elem.currentTarget.parentNode.querySelector(".acc-content")
            const accInnerHeight = b.clientHeight;
            b.style.height = accInnerHeight + 'px'
            clearTimeout(accExpandedTimer)
            setTimeout(function(){
                b.style.height = '0px';
            })
        }

        const accContainerSub = accContainer.current.querySelectorAll(".acc-container")
        for(let i=0;i<accContainerSub.length;i++) {
            accContainerSub[i].addEventListener("mouseenter", accordionOver)
            accContainerSub[i].addEventListener("mouseleave", accordionOut)
        }

        return () => {
            for(let i=0;i<accContainerSub.length;i++) {
                accContainerSub[i].removeEventListener("mouseenter", accordionOver)
                accContainerSub[i].removeEventListener("mouseleave", accordionOut)
            }
        }
    }, []);

    return (
        <div ref={accContainer}>
            {accHTMLArray}
        </div>
    )
}

export default Accordion