import { handleSwipeMouseDown, handleSwipeMouseUp } from '../../libraries/swipeList'
import dropDown from '../../libraries/dropDown'
import Card from './Card'
import Odds from '../Odds'
import '../../assets/css/live-stream.scss';
import Msports from '../../assets/images/logos/m-sports-black.svg'
import SabaSports from '../../assets/images/logos/saba-sports-black.svg'
import DummyVid from '../../assets/images/misc/dummy-vid.png'
import { useRef, useEffect } from 'react';

const HomeModule = (props) => {
	const lSWrapRef = useRef()
	const liveStreamCol1Ref = useRef()
	const swipeRef = useRef()
	const ddRef = useRef()
	const { handleDDClick, ddActive } = dropDown(ddRef)

	useEffect(() => {
		let imgTotal = lSWrapRef.current.querySelectorAll('img').length;
		lSWrapRef.current.querySelectorAll('img').forEach(res => {
			res.onload = function() {
				imgTotal--
				if(!imgTotal) {
					swipeRef.current.style.height = liveStreamCol1Ref.current.offsetHeight+'px'
				}
			}
		})

		// let sbHeight = swipeRef.current.offsetHeight * (swipeRef.current.offsetHeight / swipeRef.current.querySelector('.list-swipe').offsetHeight);
		// console.log(sbHeight)
	}, []);

      return (
		<div className="live-stream" ref={lSWrapRef}>
			<div className="heading-row row m-b-40">
				<div className="row row-flex g-16">
					<div className="col col-1">{props.heading}</div>
					<div className="col col-2">
						<div className={"dropdown"+((ddActive) ? " active": "")} ref={ddRef} onClick={() => handleDDClick}>
							<div className="selected item row-flex flex-b"><span>SHOW ALL</span><i className="icon icon-caret-down-dark s-16"></i></div>
							<div className="options">
								<div className="item"><span>SHOW ALL</span></div>
								<div className="item"><span>SOCCER</span></div>
								<div className="item"><span>TENNIS</span></div>
								<div className="item"><span>BASKETBALL</span></div>
								<div className="item"><span>BASEBALL</span></div>
								<div className="item"><span>BOXING</span></div>
								<div className="item"><span>MIXED MARTIAL ARTS</span></div>
								<div className="item"><span>E-SPORTS</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row row-flex flex-b g-16">
				<div className="col col-1" ref={liveStreamCol1Ref}>
					<div className="row row-1">
						<Card />
						<img src={DummyVid} alt="dummy vid" />
					</div>
					<div className="row row-2">
						<Odds />
					</div>
					<div className="row row-3 row-flex flex-b g-16">
						<div className="col col-1 row-flex flex-b">
							<img src={Msports} alt="M Sports" />
							<button className="btn h-24 w-120"><span>BET NOW</span></button>
						</div>
						<div className="col col-2 row-flex flex-b">
							<img src={SabaSports} alt="Saba Sports" />
							<button className="btn h-24 w-120" disabled><span>MAINTENANCE</span></button>
						</div>
					</div>
				</div>
				<div className="col col-2">
					<div className="overflow-swipe score-card-list" ref={swipeRef} onMouseDown={handleSwipeMouseDown}
					onMouseUp={handleSwipeMouseUp} onMouseLeave={handleSwipeMouseUp}>
						<div className="list-swipe swipe-y">
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
							<div className="row m-b-16">
								<Card />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HomeModule
