import React from 'react'

const Card = () => {
      return (
            <div className="card score-card">
                  <div className="row row-1 m-b-8 row-flex flex-b">
                        <div className="col col-1">La Liga</div>
                        <div className="col col-2 row-flex flex-s g-8">
                              <div>00:00:00</div>
                              <div className="tag live">LIVE</div>
                        </div>
                  </div>
                  <div className="row row-2 row-flex flex-b">
                        <div className="col col-1 row-flex flex-s g-16"><i className="icon icon-arsenal s-48"></i><span>Arsenal</span></div>
                        <div className="col col-2">1 : 1</div>
                        <div className="col col-3 row-flex flex-e g-16"><span>Manchester City</span><i className="icon icon-manchester-city s-48"></i></div>
                  </div>
            </div>
      )
}

export default Card
