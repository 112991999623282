import { handleSwipeMouseDown, handleSwipeMouseUp } from '../../libraries/swipeList'
import Card from './Card'
import basketball from '../../assets/images/misc/top-news/basketball-1.png'

const RelatedNews = () => {
      return (
            <>
                  <h4 className="row m-b-40">RELATED NEWS</h4>
                  <div className="overflow-swipe related-news-list" onMouseDown={handleSwipeMouseDown}
                  onMouseUp={handleSwipeMouseUp} onMouseLeave={handleSwipeMouseUp}>
                        <ul className="list-swipe swipe-x row-flex flex-s g-16">
                              <li>
                                    <Card src={basketball} card-class="style-2" alt="Basketball"
                                    cat="Basketball" date="June 19, 2021"
                                    title="News headline better in lowercase and  check longer"
                                    excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                              </li>
                              <li>
                                    <Card src={basketball} card-class="style-2" alt="Basketball"
                                    cat="Basketball" date="June 19, 2021"
                                    title="News headline better in lowercase and  check longer"
                                    excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                              </li>
                              <li>
                                    <Card src={basketball} card-class="style-2" alt="Basketball"
                                    cat="Basketball" date="June 19, 2021"
                                    title="News headline better in lowercase and  check longer"
                                    excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                              </li>
                              <li>
                                    <Card src={basketball} card-class="style-2" alt="Basketball"
                                    cat="Basketball" date="June 19, 2021"
                                    title="News headline better in lowercase and  check longer"
                                    excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                              </li>
                        </ul>
                  </div>
            </>
      )
}

export default RelatedNews
