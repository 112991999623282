import { NavLink } from 'react-router-dom'
import PromoBanner from '../PromoBanner'
import RelatedNews from './RelatedNews'
import '../../assets/css/news.scss'
import BGMGoal from '../../assets/images/bg/promo-cards/sports/m-goal.jpg'
import BGMSports from '../../assets/images/bg/promo-cards/sports/m-sports.jpg'
import BGSabaSports from '../../assets/images/bg/promo-cards/sports/saba-sports.jpg'
import LogoMGoal from '../../assets/images/logos/m-goal-white.svg'
import LogoMSports from '../../assets/images/logos/m-sports-white.svg'
import LogoSabaSports from '../../assets/images/logos/saba-sports-white.svg'
import PostImg from '../../assets/images/misc/top-news/soccer-1-single.jpg'
import { snapToTop, backToTop } from '../../libraries/scripts'

const Single = () => {
      snapToTop()

      return (
            <div className="container news single-post">
                  <div className="inner-box">
                        <h1>Soccer News Headline - Latest News and Highlights - MGOAL</h1>
                        <ul className="meta row row-flex flex-s m-b-40">
                              <li><NavLink to="/news/category/soccer" className="link"><strong>Soccer</strong></NavLink></li>
                              <li><NavLink to="/news/category/premier-league" className="link"><strong>Premier League</strong></NavLink></li>
                              <li>Published: June 15, 2021</li>
                        </ul>
                        <div className="post-image row m-b-40"><img src={PostImg} alt="News img" /></div>
                        <p>Jazz it up a little. I need a website. How much will it cost can you make it faster?. Theres all this spanish text on my site. Start on it today and we will talk about what i want next time . I want you to take it to the next level i'll know it when i see it give us a complimentary logo along with the website, but can the black be darker for remember, everything is the same or better and we need more images of groups of people having non-specific types of fun. That's great, but we need to add this 2000 line essay. This was not according to brief im not sure, try something else, nor can you please send me the design specs again? anyway, you are the designer, you know what to do can you use a high definition screenshot can you put "find us on facebook" by the facebook logo? but thanks for taking the time to make the website, but i already made it in wix. Can you make it look more designed mmm, exactly like that, but different and there are more projects lined up charge extra the next time, or thats not what i saw in my head at all.</p>
                        <p>That will be a conversation piece I need a website. How much will it cost i know you've made thirty iterations but can we go back to the first one that was the best version yet we are your relatives this red is too red so there is too much white space can you please change the color theme of the website to pink and purple? make the logo a bit smaller because the logo is too big can you link the icons to my social media accounts? oh and please put pictures of cats everywhere. This is just a 5 minutes job submit your meaningless business jargon to be used on the site! or can you rework to make the pizza look more delicious. Try making it a bit less blah can you please send me the design specs again? but can you make the blue bluer? so we are your relatives, so pass the mayo, appeal to the client, sue the vice president or was i smoking crack when i sent this? hahaha!. Concept is bang on, but can we look at a better execution can it handle a million in one go for could you do an actual logo instead of a font concept is bang on, but can we look at a better execution the flier should feel like a warm handshake. The website doesn't have the theme i was going for will royalties in the company do instead of cash and can you rework to make the pizza look more delicious, yet labrador.</p>
                        <p>There are more projects lined up charge extra the next time I really like the colour but can you change it. The website doesn't have the theme i was going for can we have another option it needs to be the same, but totally different for jazz it up a little, yet I like it, but can the snow look a little warmer. Remember, everything is the same or better. Can you put "find us on facebook" by the facebook logo? is this the best we can do, yet that sandwich needs to be more playful. This was not according to brief the hair is just too polarising can you remove my double chin on my business card photo? i don't like the way it looks. I know somebody who can do this for a reasonable cost. Doing some work for us "pro bono" will really add to your portfolio i promise i think this should be fairly easy so if you just want to have a look, can you turn it around in photoshop so we can see more of the front will royalties in the company do instead of cash it's great, can you add a beard though .</p>
                        <PromoBanner bg-src={BGMSports} logo-src={LogoMSports}
                        slogan="MAKE SPORTS MORE EXCITING" btn-txt="PLAY NOW"
                        banner-class="full-width full-width-small msports-banner" />
                        <p>Remember, everything is the same or better I have an awesome idea for a startup, i need you to build it for me was i smoking crack when i sent this? hahaha! this red is too red could you do an actual logo instead of a font, nor there are more projects lined up charge extra the next time. Concept is bang on, but can we look at a better execution we need more images of groups of people having non-specific types of fun. I think this should be fairly easy so if you just want to have a look submit your meaningless business jargon to be used on the site! can you make it pop can you punch up the fun level on these icons. Start on it today and we will talk about what i want next time can you make it look like this clipart i found or jazz it up a little, for we have big contacts we will promote you, or was i smoking crack when i sent this? hahaha!, could you rotate the picture to show the other side of the room?. I was wondering if my cat could be placed over the logo in the flyer you might wanna give it another shot, but make it original, so can you make it faster? something summery; colourful, make it look like Apple, or I think we need to start from scratch.</p>
                        <p>Can the black be darker could you rotate the picture to show the other side of the room? can you make it faster? I got your invoice...it seems really high, why did you charge so much will royalties in the company do instead of cash. Can you help me out? you will get a lot of free exposure doing this we need to make the new version clean and sexy we are a startup.</p>
                        <PromoBanner bg-src={BGSabaSports} logo-src={LogoSabaSports}
                        slogan="EXPERIENCE SPORTS IN A NEW WAY" btn-txt="PLAY NOW"
                        banner-class="full-width full-width-small saba-sports-banner" />
                        <p>But i cant pay you is this the best we can do, so what is a hamburger menu yet make it sexy. I have printed it out, but the animated gif is not moving theres all this spanish text on my site we don't need a backup, it never goes down!. Will royalties in the company do instead of cash submit your meaningless business jargon to be used on the site!. We are a startup we exceed the clients' expectations. Can you make pink a little more pinkish that will be a conversation piece. We need more images of groups of people having non-specific types of fun submit your meaningless business jargon to be used on the site! or im not sure, try something else, yet we are a startup, so could you rotate the picture to show the other side of the room? that's going to be a chunk of change i think this should be fairly easy so if you just want to have a look.</p>
                        <p>Make it sexy theres all this spanish text on my site anyway, you are the designer, you know what to do, or try a more powerful colour, for labrador. I really like the colour but can you change it use a kpop logo that's not a kpop logo! ugh. Can you rework to make the pizza look more delicious this turned out different that i decscribed. Can you lower the price for the website? make it high quality and please use html can you make the font a bit bigger and change it to times new roman? jazz it up a little bit make the picture of the cupcake look delicious make the purple more well, purple-er it looks so empty add some more hearts can you add a bit of pastel pink and baby blue because the purple alone looks too fancy okay can you put a cute quote on the right side of the site? oh no it looks messed up! i think we need to start from scratch this is just a 5 minutes job doing some work for us "pro bono" will really add to your portfolio i promise, i cant pay you</p>
                        <PromoBanner bg-src={BGMGoal} logo-src={LogoMGoal}
                        slogan="100% WELCOME BONUS UP TO 0.8% DAILY REBATES" btn-txt="PLAY NOW"
                        banner-class="full-width full-width-small mgoal-banner" />
                        <div className="talign-center row m-b-48">
                              <button className="btn transparent h-40 back-to-top-btn" onClick={backToTop}>
                                    <span>BACK TO TOP</span><i className="icon icon-caret-down-dark-v2 flip-y s-7"></i>
                              </button>
                        </div>
                        <RelatedNews />
                  </div>
            </div>
      )
}

export default Single
