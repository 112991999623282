import '../../assets/css/cards.scss'
import '../../assets/css/icons/icons-casino.scss'
import BGMGoal from '../../assets/images/bg/promo-cards/sports/m-goal.jpg'
import LogoMGoal from '../../assets/images/logos/m-goal-white.svg'
import MatchSched from '../match-sched/HomeModule'
import LiveStream from '../live-stream/HomeModule'
import TopNews from '../news/HomeModule'
import PromoBanner from '../PromoBanner'
import LiveCasino from '../live-casino/HomeModule'
import Casino from '../casino/HomeModule'
import RecentlyPlayed from '../recently-played'
import { useSelector } from 'react-redux'

const Index = () => {
	const loginUser = useSelector(state => state.loginUser)

	return (
		<div className="container">
			<div className="section-list">
				<section className="odd no-padding">
					<div className="inner-box">
						<div className="section-row">
							<MatchSched heading={<h3>MATCH SCHEDULES</h3>} />
						</div>
					</div>
				</section>
				{loginUser &&
					<section>
						<div className="inner-box">
							<div className="section-row">
								<RecentlyPlayed heading={<h3>RECENTLY PLAYED</h3>} />
							</div>
						</div>
					</section>
				}
				<section className="even">
					<div className="inner-box">
						<div className="section-row">
							<LiveStream heading={<h3>LIVE STREAM</h3>} />
						</div>
					</div>
				</section>
				<section className="odd">
					<div className="inner-box">
						<div className="section-row row m-b-48">
							<TopNews heading={<h3>TOP NEWS</h3>} />
						</div>
						<div className="section-row row m-b-48">
							<PromoBanner bg-src={BGMGoal} logo-src={LogoMGoal}
							slogan="100% WELCOME BONUS UP TO 0.8% DAILY REBATES" btn-w="200" banner-class="full-width" btn-txt="JOIN NOW" />
						</div>
						<div className="section-row row m-b-48">
							<LiveCasino heading={<h3>LIVE CASINO</h3>} />
						</div>
						<div className="section-row row m-b-48">
							<Casino heading={<h3>CASINO GAMES</h3>} />
						</div>
					</div>
				</section>
			</div>
		</div>
	)
}

export default Index
