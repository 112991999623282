import './assets/css/index.scss'
import './assets/css/accordion.scss'
import './assets/css/dropdown.scss'
import './assets/css/icons/icons-common.scss'
import Header from './components/header'
import Footer from './components/footer'
import Home from './components/home'
import NewsSinglePost from './components/news/Single'
import News from './components/news'
import Login from './components/sign-access/Login'
import Register from './components/sign-access/Register'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

function App() {
	const showLogin = useSelector(state => state.showLogin)
	const showRegister = useSelector(state => state.showRegister)

	return (
		<Router>
			<Header />
			<div id="main">
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/news" exact component={News} />
					<Route path="/news/single-post" component={NewsSinglePost} />
				</Switch>
			</div>
			<Footer />
			{showLogin && <Login />}
			{showRegister && <Register />}
			{showLogin && document.body.classList.add('popup-active', 'animate-in') }
			{!showLogin && document.body.classList.remove('popup-active', 'animate-in') }
		</Router>
	)
}

export default App;