import '../../assets/css/register.scss'
import MGoalLogo from '../../assets/images/logos/m-goal-white.svg'

const Register = () => {
	document.body.classList.add('popup-active');

	return (
		<div className="popup popup-register light">
			<div className="row">
				<div className="col col-1">
					<div className="inner">
						<div className="row m-b-24"><img src={MGoalLogo} className="logo" alt="M Goal" /></div>
						<h4>JOIN THE BEST SOURCE FOR SPORTS NEWS AND ODDS</h4>
					</div>
				</div>
				<div className="col col-2">
					<div className="popup-heading row-flex flex-b">
						<div className="col col-1"><strong>REGISTER TO MGOAL88!</strong></div>
						<div className="col col-2">
							<button><i className="icon icon-close-white s-16"></i></button>
						</div>
					</div>
					<div className="popup-content">
						<div className="inner">
							<div className="row m-b-24 sec-txt-1">
								<p className="txt txt-1">Please note that we do not permit members to own more then one (1) account.</p>
								<p className="txt txt-2">Already have an account? Login here!</p>
							</div>
							<form>
								<div className="row m-b-24 row-flex flex-b">
									<div className="col col-1"><strong>Name</strong></div>
									<div className="col col-2"><input type="text" placeholder="How do we address you?" /></div>
								</div>
								<div className="row m-b-24 row-flex flex-b">
									<div className="col col-1"><strong>Username</strong></div>
									<div className="col col-2"><input type="text" placeholder="8-10 alphanumeric characters long" /></div>
								</div>
								<div className="row m-b-24 row-flex flex-b">
									<div className="col col-1"><strong>Password</strong></div>
									<div className="col col-2"><input type="password" placeholder="8-10 characters long" /></div>
								</div>
								<div className="row m-b-24 row-flex flex-b">
									<div className="col col-1"><strong>E-Mail</strong></div>
									<div className="col col-2"><input type="text" placeholder="Example@gmail.com" /></div>
								</div>
								<div className="row m-b-24 row-flex flex-b">
									<div className="col col-1"><strong>Mobile Number</strong></div>
									<div className="col col-2"><input type="text" placeholder="+XX XXXXXXXXXX" /></div>
								</div>
								<div className="row m-b-32">
									<div className="sub-note">
										<p className="txt-3">DAILY REBATE UP TO 1%, NO LIMIT & NO ROLLOVER</p>
										<p className="txt-4">Complete your first deposit and visit the rewards section to claim it!</p>
									</div>
								</div>
								<div className="row m-b-24">
									<div className="tnc-policy">By registering, you agree to MGoal88’s terms of use and privacy policy.</div>
								</div>
								<div className="row btn-row">
									<div className="row m-b-8"><button className="btn w-288 h-40"><span>REGISTER</span></button></div>
									<button className="btn-cancel"><span>Cancel</span></button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Register