import Card from './Card'
import '../../assets/css/live-casino.scss'
import LaligaRoom from '../../assets/images/bg/promo-cards/live-casino/laliga-room.png'
import ClubM88Europe from '../../assets/images/bg/promo-cards/live-casino/club-m88-europe.png'

const HomeModule = (props) => {
      return (
            <div className="live-casino">
                  <div className="heading-row row m-b-40">
                        {props.heading}
                  </div>
                  <div className="row row-flex flex-b g-16">
                        <div className="col col-1">
                              <Card bg-src={LaligaRoom} btn-txt="PLAY NOW" />
                        </div>
                        <div className="col col-2">
                              <Card bg-src={ClubM88Europe} btn-txt="MAINTENANCE" deactive />
                        </div>
                  </div>
            </div>
      )
}

export default HomeModule
