// import Axios from 'axios'
import { snapToTop, backToTop } from '../../libraries/scripts'
import MatchSched from '../match-sched/HomeModule'
import TopNews from '../news/HomeModule'
import LiveStream from '../live-stream/HomeModule'
import Card from './Card'
import PromoBanner from '../PromoBanner'
import basketball from '../../assets/images/misc/top-news/basketball-1.png'
import BGMGoal from '../../assets/images/bg/promo-cards/sports/m-goal.jpg'
import LogoMGoal from '../../assets/images/logos/m-goal-white.svg'

const index = () => {
      snapToTop()

      return (
            <div className="container news-page">
                  <div className="section-list">
                        <section className="even no-padding">
                              <div className="inner-box">
                                    <div className="section-row">
                                          <MatchSched heading={<h3>MATCH SCHEDULES</h3>} />
                                    </div>
                              </div>
                        </section>
                        <section className="odd">
                              <div className="inner-box">
                                    <div className="section-row">
                                          <TopNews heading={<h1>NEWS</h1>} />
                                    </div>
                              </div>
                        </section>
                        <section className="even">
                              <div className="inner-box">
                                    <div className="section-row">
                                          <LiveStream heading={<h3>LIVE STREAM</h3>} />
                                    </div>
                              </div>
                        </section>
                        <section className="odd">
                              <div className="inner-box">
                                    <div className="section-row">
                                          <div className="row row-flex g-16 m-b-32 equal-col">
                                                <div className="col col-1">
                                                      <Card src={basketball} alt="Basketball"
                                                      cat="Basketball" date="June 19, 2021" card-class="style-3"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                                <div className="col col-2">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                          </div>
                                          <div className="row row-flex g-16 m-b-48 equal-col">
                                                <div className="col col-1">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                                <div className="col col-2">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                          </div>
                                          <div className="row m-b-48">
                                                <PromoBanner bg-src={BGMGoal} logo-src={LogoMGoal}
                                                slogan="100% WELCOME BONUS UP TO 0.8% DAILY REBATES" btn-w="200" banner-class="full-width" btn-txt="JOIN NOW" />
                                          </div>
                                          <div className="row row-flex g-16 m-b-32 equal-col">
                                                <div className="col col-1">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                                <div className="col col-2">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                          </div>
                                          <div className="row row-flex g-16 m-b-32 equal-col">
                                                <div className="col col-1">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                                <div className="col col-2">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                          </div>
                                          <div className="row row-flex g-16 m-b-32 equal-col">
                                                <div className="col col-1">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                                <div className="col col-2">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                          </div>
                                          <div className="row row-flex g-16 m-b-48 equal-col">
                                                <div className="col col-1">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                                <div className="col col-2">
                                                      <Card src={basketball} alt="Basketball" card-class="style-3"
                                                      cat="Basketball" date="June 19, 2021"
                                                      title="News headline better in lowercase and  check longer"
                                                      excerpt="I have a hard stop in an hour and half. Run it up the flagpole, ping the boss and circle back deploy to production per my the boss and circle back deploy to proasdasdasdasda" />
                                                </div>
                                          </div>
                                          <div className="talign-center row">
                                                <button className="btn transparent h-40 back-to-top-btn" onClick={backToTop}>
                                                      <span>BACK TO TOP</span><i className="icon icon-caret-down-dark-v2 flip-y s-7"></i>
                                                </button>
                                          </div>
                                    </div>
                              </div>
                        </section>
                  </div>
            </div>
      )
}

export default index
