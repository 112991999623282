const Card = (props) => {
      return (
            <div className="card casino-card">
                  <div className="img-holder"><img src={props['thumb-src']} alt={props.title} /></div>
                  <div className="meta row-flex g-8">
                        <i className={"icon icon-"+props.provider+" s-24"}></i><span className="title">{props.title}</span>
                  </div>
            </div>
      )
}

export default Card
