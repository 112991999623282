const loginReducer = (state = false, action) => {
      switch(action.type) {
            case 'show_login':
                  return true
            case 'hide_login':
                  return false
            default:
                  return state
      }
}

export default loginReducer